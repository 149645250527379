<button
  [disabled]="disabled"
  [type]="type"
  data-te-ripple-init
  data-te-ripple-color="light"
  [class.btn--primary]="color === 'primary'"
  [class.btn--secondary]="color === 'secondary'"
  [class.btn--warn]="color === 'warn'"
  [class.btn--error]="color === 'error'"
  [class.btn--basic]="appearance === 'basic'"
  [class.btn--raised]="appearance === 'raised'"
  [class.btn--filled]="appearance === 'fill'"
  [class.btn--outline]="appearance === 'outline'"
  [class.btn--pill]="shape === 'pill'"
  [class.btn--rounded]="shape === 'rounded'"
  class="btn w-full px-6 py-2.5 font-bold text-xs leading-tight focus:outline-none focus:ring-0 active:shadow-lg transition ease-in-out select-none"
  (click)="clicked.emit()"
>
  <ng-content></ng-content>
</button>
