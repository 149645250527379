import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeColor } from '../../../../models/theme.model';

@Component({
  standalone: true,
  selector: 'a-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [CommonModule],
})
export class ButtonComponent {
  @Input() disabled = false;

  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() color: ThemeColor = 'dark';
  @Input() appearance: 'basic' | 'raised' | 'outline' | 'fill' = 'basic';
  @Input() shape: 'rounded' | 'pill' = 'rounded';

  @Output() clicked = new EventEmitter();
}
