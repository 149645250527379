<button
  [disabled]="disabled"
  [type]="type"
  data-mdb-ripple="true"
  data-mdb-ripple-color="light"
  [class.btn--primary]="color === 'primary'"
  [class.btn--secondary]="color === 'secondary'"
  [class.btn--warn]="color === 'warn'"
  [class.btn--error]="color === 'error'"
  class="btn h-full w-full rounded-full focus:outline-none focus:ring-0 active:shadow-lg transition ease-in-out select-none"
  (click)="clicked.emit()"
>
  <ng-content></ng-content>
</button>
