import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ToastEvent } from '../../../../models';
import { ToastService } from '../../../../services/toast.service';
import { ToastComponent } from './toast/toast.component';

@Component({
  selector: 'o-toast-container',
  standalone: true,
  imports: [CommonModule, ToastComponent],
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
})
export class ToastContainerComponent {
  @Input()
  maxMessages = 10;

  currentToasts: ToastEvent[] = [];

  constructor(
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
  ) {
    this.subscribeToToasts();
  }

  subscribeToToasts() {
    this.toastService.toastEvents.subscribe((toast) => {
      const toastIndex = this.currentToasts.findIndex(
        (t) => (toast.message && toast.message === t.message) || (toast.innerHtml && toast.innerHtml === t.innerHtml),
      );
      if (toastIndex >= 0) {
        this.currentToasts.splice(toastIndex, 1);
      }
      if (this.currentToasts.length < this.maxMessages) {
        this.currentToasts.push(toast);
      }
      this.cdr.detectChanges();
    });
  }

  dispose(index: number) {
    this.currentToasts.splice(index, 1);
    this.cdr.detectChanges();
  }
}
