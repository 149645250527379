<div
  class="toast relative bg-primary py-1 px-6 rounded flex flex-col justify-center items-center text-center"
  [class.toast--secondary]="color === 'secondary'"
  [class.toast--tertiary]="color === 'tertiary'"
  [class.toast--dark]="color === 'dark'"
  [class.toast--light]="color === 'light'"
  [class.toast--error]="color === 'error'"
  [class.toast--hidden]="hidden"
>
  <a-fab-button class="absolute top-1/2 -translate-y-1/2 right-0 h-6 w-6" (click)="hide()"
    ><fa-icon [icon]="closeIcon"></fa-icon
  ></a-fab-button>
  <ng-content></ng-content>
</div>
