import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeColor } from '../../../../models/theme.model';

@Component({
  standalone: true,
  selector: 'a-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss'],
})
export class FabButtonComponent {
  @Input() disabled = false;

  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() color: ThemeColor = 'dark';

  @Output() clicked = new EventEmitter();
}
