import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastEvent } from '../models';
import { ThemeColor } from '../models/theme.model';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastEvents: Observable<ToastEvent>;
  private _toastEvents = new Subject<ToastEvent>();

  constructor() {
    this.toastEvents = this._toastEvents.asObservable();
  }

  showToast(message: string, color: ThemeColor) {
    this._toastEvents.next({
      message,
      color,
    });
  }

  showToastHtml(innerHtml: string, color: ThemeColor) {
    this._toastEvents.next({
      innerHtml,
      color,
    });
  }
}
