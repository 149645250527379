import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ThemeColor } from '../../../../../models/theme.model';
import { FabButtonComponent } from '../../../atoms';

@Component({
  selector: 'a-toast',
  standalone: true,
  imports: [CommonModule, FabButtonComponent, FontAwesomeModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  @Input() title = '';
  @Input() color: ThemeColor = 'primary';

  @Output() disposeEvent = new EventEmitter();

  @ViewChild('toastElement', { static: true })
  toastEl!: ElementRef;

  closeIcon = faClose;
  hidden = false;

  ngOnInit(): void {
    setTimeout(() => this.hide(), 5000);
  }

  hide() {
    this.hidden = true;
    setTimeout(() => this.disposeEvent.emit(), 100);
  }
}
